import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const routes = [
    {
        path: '/',
        name: 'select-shop',
        component: () => import('@/views/SelectShopView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: false,
            requiresShopSub: false,
            allow: ['all']
        }
    },
    {
        path: '/create-shop/',
        name: 'create-shop',
        component: () => import('@/views/CreateShopView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: false,
            requiresShopSub: false,
            allow: ['all']
        }
    },
    {
        path: '/plan/:action?/',
        name: 'plan',
        component: () => import('@/views/PlanView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: false,
            allow: ['owner']
        }
    },
    {
        path: '/dashboard/',
        name: 'dashboard',
        component: () => import('@/views/DashboardView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: true,
            allow: ['all']
        }
    },
    {
        path: '/add-item/',
        name: 'add-item',
        component: () => import('@/views/AddItemView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: true,
            allow: ['all']
        }
    },
    {
        path: '/list-items/',
        name: 'list-items',
        component: () => import('@/views/ListItemsView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: true,
            allow: ['all']
        }
    },
    {
        path: '/edit-item/:itemId',
        name: 'edit-item',
        component: () => import('@/views/EditItemView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: true,
            allow: ['all']
        }
    },
    {
        path: '/orders/',
        name: 'orders',
        component: () => import('@/views/OrdersView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: true,
            allow: ['all']
        }
    },
    {
        path: '/order/uid/:orderUid',
        name: 'order-details',
        component: () => import('@/views/OrderDetailsView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: true,
            allow: ['all']
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/NotFoundView.vue'),
        meta: {
            requiresAuth: false,
            requiresShopAuth: false,
            requiresShopSub: false,
            allow: ['all']
        }
    },
    {
        path: '/forbidden/',
        name: 'forbidden',
        component: () => import('@/views/ForbiddenView.vue'),
        meta: {
            requiresAuth: false,
            requiresShopAuth: false,
            requiresShopSub: false,
            allow: ['all']
        }
    },

    
    // Shop account routes
    {
        path: '/shop-details/index/',
        name: 'shop-details',
        component: () => import('@/views/ShopDetailsView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: true,
            allow: ['all']
        }
    },
    {
        path: '/shop-details/edit/',
        name: 'shop-details-edit',
        component: () => import('@/views/ShopDetailsEditView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: true,
            allow: ['owner']
        }
    },
    {
        path: '/payment-details/',
        name: 'payment-details',
        component: () => import('@/views/PaymentDetailsView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: true,
            allow: ['owner']
        }
    },
    {
        path: '/withdrawal-history/',
        name: 'withdrawal-history',
        component: () => import('@/views/WithdrawalHistoryView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: true,
            allow: ['owner']
        }
    },
    {
        path: '/subscription/',
        name: 'subscription',
        component: () => import('@/views/SubscriptionView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: false,
            allow: ['owner']
        }
    },
    {
        path: '/receipts/',
        name: 'receipts',
        component: () => import('@/views/ReceiptsView.vue'),
        meta: {
            requiresAuth: true,
            requiresShopAuth: true,
            requiresShopSub: false,
            allow: ['owner']
        }
    },


    // User account routes
    {
        path: "/profile/",
        name: "profile",
        component: () => window.location.href = `${process.env.VUE_APP_MAIN_DOMAIN}/accounts/profile/index/`,
        meta: {
            requiresAuth: true,
            requiresShopAuth: false,
            requiresShopSub: false,
            allow: ['all']
        }
    },
    {
        path: "/signout/",
        name: "sign-out",
        component: () => window.location.href = `${process.env.VUE_APP_MAIN_DOMAIN}/accounts/signout/`,
        meta: {
            requiresAuth: false,
            requiresShopAuth: false,
            requiresShopSub: false,
            allow: ['all']
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition){
        if(savedPosition){
            return savedPosition
        }else{
            return {left: 0, top: 0}
        }
    }
})

router.beforeEach((to, from, next) => {
    axios.get(`/user/auth/status`)
    .then((response) => {

        // check if view requires user auth
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

        if(requiresAuth && !response.data.allow){

            window.location.replace(`${process.env.VUE_APP_MAIN_DOMAIN}/signin/?next=${encodeURIComponent(window.location.href)}`)

        }else{

            axios.get(`/shop/auth/status`)
            .then((response) => {

                // check if view requires shop auth
                const requiresShopAuth = to.matched.some(record => record.meta.requiresShopAuth)

                if(requiresShopAuth && !response.data.allow){

                    next({ name: 'select-shop', query: { 'next': encodeURIComponent(to.fullPath) } })

                }else{

                    const isAuthorized = (response.data.allow) ? to.meta.allow.includes(response.data.shop.user_role) : true

                    if(!isAuthorized && to.meta.allow[0] != 'all'){

                        next({ name: 'forbidden' })

                    }else{

                        // check if view requires shop subscription plan
                        const requiresShopSub = to.matched.some(record => record.meta.requiresShopSub)

                        if(requiresShopSub && !response.data.shop.shop_sub){

                            next({ name: 'plan', params: { 'action': 'purchase' } })

                        }else{

                            next()
                        }

                    }
                }

            })

        }

    })
})

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start()
    }
    next()
})
  
router.afterEach(() => {
    NProgress.done()
})


export default router
