import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh"

createAuthRefreshInterceptor(axios, () => {
    window.location.replace(window.location.href)
    return Promise.resolve()
});

axios.defaults.baseURL = process.env.VUE_APP_API_DOMAIN;
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'CSRFTOKEN';
axios.defaults.xsrfHeaderName = 'csrf-token';