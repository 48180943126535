import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import './axios'
import Notifications from '@kyvg/vue3-notification'

createApp(App)
    .use(router)
    .use(VueCookies)
    .use(Notifications)
    .mount('#app')
